import React, { useContext, Fragment } from 'react'

import { fetchContent } from '../../Content'
import useEffectAsync from '../../useEffectAsync'
import SiteContext from '../../Context/SiteContext'
import Slot from '../../Slots/Slot'

import './Home.scss'

const home = () => {
  const siteContext = useContext(SiteContext)

  useEffectAsync(async () => {
    if (siteContext.data.url === window.location.pathname) {
      return false
    }

    const fetchData = await fetchContent('home', '')

    if (fetchData.error === 0) {
      const data = fetchData.resources.page
      data['type'] = ''
      data['url'] = window.location.pathname
      siteContext.setData(data)
    } else {
      window.location.href = '/404'
    }
  }, [])

  return (
    <Fragment>
      <div className="row">
        <Slot id="3" />
      </div>
    </Fragment>
  )
}

export default home
