import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import SiteContext from '../../Context/SiteContext'

import './Logo.scss'

const logo = props => {
  const siteContext = useContext(SiteContext)

  return (
    <Link
      to="/"
      className="logo"
      onClick={() => {
        siteContext.setShowMenu(false)
      }}
      title="Strona gówna"
    >
      Posłaniec
      <br />
      Serca
      <br />
      Jezusowego
    </Link>
  )
}

export default logo
