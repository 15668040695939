import React from 'react'
import ReactDom from 'react-dom'
import './assets/styles/index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

ReactDom.render(
  <GoogleReCaptchaProvider reCaptchaKey="6LfQNpgUAAAAACNGaMegymsuCBCncEbevyGqhZyt" language="pl">
    <App />
  </GoogleReCaptchaProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
