import Containers from './Containers/Containers'
import { SYSTEM_ROUTES } from './Config/Endpoints'

export const fetchRouters = async () => {
  try {
    const response = await fetch(SYSTEM_ROUTES)
    const json = await response.json()

    const filteredJson = json.resources.routes.filter(el => {
      return el != null
    })

    const mappedJson = filteredJson.filter(el => {
      el['path'] = el['slug']
      el['type'] = el['component']
      el['component'] = Containers[el['component']]
      return el
    })

    return mappedJson
  } catch (e) {
    console.error(e)
  }
}
