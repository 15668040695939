import React, { useContext } from 'react'

import SiteContext from '../../Context/SiteContext'
import Slot from '../../Slots/Slot'

import Logo from './Logo'

import './Header.scss'

const header = props => {
  const siteContext = useContext(SiteContext)

  return (
    <header
      className={'main-header' + (!siteContext.isHomePage ? ' collapse' : '')}
    >
      <Slot id="2" />
      <div className="row">
        <Logo />
        <div className="hamburger" onClick={siteContext.setShowMenu}>
          <span />
        </div>
      </div>
    </header>
  )
}

export default header
