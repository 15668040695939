import React from 'react'

const waiNavigation = props => {
  return (
    <ul className="wai-navigation">
      <li>
        <a href="#main-menu">Przejdź do menu głównego</a>
      </li>
      <li>
        <a href="#main-content">Przejdź do treści</a>
      </li>
      {window.location.pathname === '/' ? (
        <li>
          <a href="#serach-box">Przejdź do wyszukiwarki</a>
        </li>
      ) : (
        ''
      )}
    </ul>
  )
}

export default waiNavigation
