import React from 'react'

const List = React.lazy(() => import('./List/List'))
const News = React.lazy(() => import('./News/News'))
const Archive = React.lazy(() => import('./Archive/List'))
const Contact = React.lazy(() => import('./Contact/Contact'))
const User = React.lazy(() => import('./Users/User'))

const containers = {
  1: Archive,
  2: User,
  3: Contact,
  4: User,
  5: News,
  6: List
}

export default containers
