import React, { useContext, useEffect } from 'react'
import SiteContext from '../../../Context/SiteContext'

import './Message.scss'

const messages = () => {
 const siteContext = useContext(SiteContext)

 useEffect(() => {
  if (siteContext.messages.popup) {
   const messages = document.getElementById('messages')
   setTimeout(() => {
    messages.classList.add('active')
   }, 50)

   setTimeout(() => {
    messages.classList.remove('active')
   }, 1800)

   setTimeout(() => {
    siteContext.setMessages([])
   }, 2000)
  }
 }, siteContext.messages.message)

 if (siteContext.messages.length === 0) {
  return false
 }

 if (siteContext.messages.prevPath !== window.location.pathname) {
  return false
 }

 return (
  <div id="messages" className={'messages row type-' + siteContext.messages.type + (siteContext.messages.popup ? ' popup' : '')}>
   <div>
    {siteContext.messages.message.map((message, i) => {
     return (
      <div key={i}>
       {message
        ? message
        : 'Wystąpił nieczekiwany błąd. Spróbuj ponownie, a jeśli problem będzie się powtarzał skontaktuj się z administratorem serwisu.'}
      </div>
     )
    })}
   </div>
  </div>
 )
}

export default messages
