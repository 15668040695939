import React, { useContext, Suspense } from 'react'

import SiteContext from '../Context/SiteContext'

const Menu = React.lazy(() => import('./Menu'))
const News = React.lazy(() => import('./News'))
const Content = React.lazy(() => import('./Content'))
const Html = React.lazy(() => import('./Html'))
const Login = React.lazy(() => import('./Login'))
const Search = React.lazy(() => import('./Search'))
const Slider = React.lazy(() => import('./Slider'))

const slot = props => {
  const siteContext = useContext(SiteContext)
  const slots = siteContext.data.slots

  if (!slots) {
    return false
  }

  if (!slots[props.id]) {
    return false
  }

  return (
    <Suspense fallback={<div />}>
      <div className={'slot' + props.id}>
        {slots[props.id].map((slot, i) => {
          if (slot.type === '0') {
            return <News key={i} {...slot} />
          } else if (slot.type === '1') {
            return <Menu key={i} {...slot} slot={props.id} />
          } else if (slot.type === '2') {
            return <Html key={i} {...slot} />
          } else if (slot.type === '3') {
            if (slot.title === 'Slider') {
              return <Slider key={i} {...slot} />
            } else {
              return <Content key={i} {...slot} />
            }
          } else if (slot.type === '4') {
            return <Login key={i} {...slot} />
          } else if (slot.type === '5') {
            return <Search key={i} {...slot} />
          } else {
            return false
          }
        })}
      </div>
    </Suspense>
  )
}

export default slot
