import React from 'react'

const siteContext = React.createContext({
  data: false,

  showMenu: false,
  setShowMenu: () => {},

  isHomePage: false,
  setIsHomePage: () => {},

  pages: [],
  setPages: () => {}
})

export default siteContext
