import React from 'react'

import Slot from '../../Slots/Slot'
import './SecondNavigation.scss'

const secondNavigation = props => {
  return (
    <div className="top-bar">
      <div className="row">
        <div className="wcag">
          <a href="/" title={props.contrast.title} onClick={props.contrastHandler} className="contrast">
            {props.contrast.title}
          </a>
          <a href="/" title={props.fontSize.title} onClick={props.fontSizeHandler} className="font-size">
            {props.fontSize.title}
          </a>
        </div>
        <Slot id="1" />
      </div>
    </div>
  )
}

export default secondNavigation
