/* global process */

let endpoint = ''
if (window.location.hostname === 'localhost') {
  endpoint = 'https://poslaniec.net'
}

const API_ENDPOINT = endpoint

export let SYSTEM_ROUTES = API_ENDPOINT + '/api/system/routes'
export let SYSTEM_HOMEPAGE = API_ENDPOINT + '/api/system/homepage'
export let SYSTEM_SLIDER = API_ENDPOINT + '/api/system/assets/slider_1'

export let USER = API_ENDPOINT + '/api/users'
export let USER_REGISTRATION = USER + '/register'
export let USER_ACTIVATION = USER + '/activate'
export let USER_AUTH = USER + '/auth'
export let USER_FORGOT_PASSWORD = USER + '/forgot-password'
export let USER_RESET = USER + '/reset'
export let USER_CHANGE = USER + '/change'

export let CONTACT = API_ENDPOINT + '/api/contacts'
export let CONTACT_SAVE = CONTACT + '/save'

export let DIGITALIZATIONPUBLICATIONS = API_ENDPOINT + '/api/digitalizationpublications'
export let DIGITALIZATIONPUBLICATIONS_SEARCH = DIGITALIZATIONPUBLICATIONS + '/search/'
export let DIGITALIZATIONDOCUMENTS_CORRECTIONS = API_ENDPOINT + '/api/digitalizationdocuments/corrections'

export let ARTICLES = API_ENDPOINT + '/api/articles'

export let LISTS = API_ENDPOINT + '/api/lists'

export let GOOGLE_CLIENT_ID = "285592799074-mqq69v4g0851fosd8srnvp9olkv46r0g.apps.googleusercontent.com";
export let FACEBOOK_APP_ID = "895841600917436";