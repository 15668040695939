import { getData } from './Fetch'
import {
 SYSTEM_HOMEPAGE,
 SYSTEM_SLIDER,
 DIGITALIZATIONPUBLICATIONS,
 DIGITALIZATIONPUBLICATIONS_SEARCH,
 LISTS,
 CONTACT,
 ARTICLES,
 USER,
} from './Config/Endpoints'

export const fetchContent = async (type, slug = '', headers = false) => {
 try {
  let url = ''
  // todo
  // const lsKey = type + slug.replace('-', '_').replace(/\//g, '_')

  // const lsData = window.localStorage.getItem(lsKey)

  // if (lsData) {
  //   return JSON.parse(lsData)
  // }

  if (type === 'home') {
   url = SYSTEM_HOMEPAGE
  } else if (type === 'slider') {
   url = SYSTEM_SLIDER
  } else if (type === 'digitalizationpublicationsSearch') {
   url = DIGITALIZATIONPUBLICATIONS_SEARCH + slug
  } else if (type === 'digitalizationpublications') {
   url = DIGITALIZATIONPUBLICATIONS + '/page' + slug
  } else if (type === 'contacts') {
   url = CONTACT + '/page' + slug
  } else if (type === 'list') {
   url = LISTS + '/page' + slug
  } else if (type === 'articles') {
   url = ARTICLES + '/page' + slug
  } else if (type === 'users') {
   url = USER + '/page' + slug
  }

  const response = await getData(url, headers)

  if (response.error === 0) {
   // todo
   // window.localStorage.setItem(lsKey, JSON.stringify(response))
  }

  return response
 } catch (e) {
  console.error(e)
 }
}

export const fetchAuthorizationContent = async (type, url, token) => {
 return fetchContent(type, url, {
  authorization: 'Bearer ' + token,
 })
}
