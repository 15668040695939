import React from 'react'
import { NavLink } from 'react-router-dom'

import './Consent.scss'

const consent = () => {
  const consent = window.localStorage.getItem('consent')

  if (consent) {
    return false
  }

  const acceptHandler = e => {
    e.preventDefault()

    window.localStorage.setItem('consent', true)
    document.getElementById('consent').style.display = 'none'
  }

  return (
    <div id="consent">
      <p>
        Ten serwis wykorzystuje pliki cookies. Korzystanie z witryny oznacza zgodę na ich zapis lub odczyt wg ustawień przeglądarki.{' '}
        <NavLink to="/informacja-o-cookies">Informacje o ciasteczkach</NavLink>.
      </p>
      <a href="/" onClick={acceptHandler} title="Wyrażam zgodę na zapis lub odczyt plików cookie.">
        Akceptuję
      </a>
    </div>
  )
}

export default consent
