const fetchData = (url, type, data = false, headers = {}) => {
  let params = {
    method: type,
    mode: 'cors',
    // cache: 'force-cache',  // disabled because it forced to fetch data from disk cache, not live (even when request was made)
    cache: 'default',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'text/plain',
    },
    redirect: 'follow',
    // referrer: 'no-referrer',
  }

  if (data) {
    params.body = JSON.stringify(data)
  }

  if (headers) {
    params.headers = { ...headers, ...params.headers }
  }

  return fetch(url, params).then(response => response.json())
}

export const getData = (url = '', headers = false) => {
  return fetchData(url, 'GET', false, headers)
}

export const postData = (url, data = {}, headers = false) => {
  return fetchData(url, 'POST', data, headers)
}
