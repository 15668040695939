import React, { Fragment, Suspense, useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
// import TagManager from 'react-gtm-module'
import GA from 'react-ga'

import { fetchRouters } from './Routers'
import useEffectAsync from './useEffectAsync'
import SiteContext from './Context/SiteContext'
import Slot from './Slots/Slot'
import Messages from './Components/Elements/Messages/Messages'
import Consent from './Components/Elements/Consent/Consent'

import Home from './Containers/Home/Home'

import Header from './Components/Header/Header'
import SecondNavigation from './Components/SecondNavigation/SecondNavigation'
import WaiNavigation from './Components/WaiNavigation/WaiNavigation'
const ArchiveYear = React.lazy(() => import('./Containers/Archive/Year'))
const ArchiveMonth = React.lazy(() => import('./Containers/Archive/Month'))
const ArchiveView = React.lazy(() => import('./Containers/Archive/View'))
const ArchiveSearch = React.lazy(() => import('./Containers/Archive/Search'))
const NotFound = React.lazy(() => import('./Containers/NotFound/NotFound'))
// const ErrorPage = React.lazy(() => import('./Containers/ErrorPage/ErrorPage'))

const UserRegister = React.lazy(() => import('./Containers/Users/Register'))
const UserRemind = React.lazy(() => import('./Containers/Users/Remind'))
const UserActivate = React.lazy(() => import('./Containers/Users/Activate'))
const UserLogin = React.lazy(() => import('./Containers/Users/Login'))
const UserLogout = React.lazy(() => import('./Containers/Users/Logout'))
const UserReset = React.lazy(() => import('./Containers/Users/Reset'))

const app = () => {
  const [routers, setRouters] = useState([])
  const [user, setUser] = useState(false)
  const [data, setData] = useState([])
  const [showMenu, setShowMenu] = useState(false)
  const [messages, setMessages] = useState([])
  const [contrast, setContrast] = useState({})
  const [fontSize, setFontSize] = useState({})

  const defaultPages = []
  const defaultContrast = {
    state: 0,
    title: 'Wersja dla osób z niepełnosprawnościami',
  }
  const defaultFontSize = {
    state: 0,
    title: 'Czcionka średnia',
  }

  useEffect(() => {
    const date = parseInt(sessionStorage.getItem('date'), 10)
    const dateOffset = Date.now() - 24 * 60 * 60 * 1000 * 1 // - 1 days

    if (date <= dateOffset) {
      sessionStorage.clear()
      setUser(false)
    }

    setContrast(defaultContrast)
    setFontSize(defaultFontSize)
  }, [])

  useEffectAsync(async () => {
    let userData = sessionStorage.getItem('user')
    if (userData) {
      userData = JSON.parse(userData)
      setUser(userData)
    }
  }, [])

  useEffectAsync(async () => {
    const routers = await fetchRouters()
    setRouters(routers)
  }, [])

  let content = null

  if (routers && routers.length) {
    content = (
      <Suspense fallback={<div />}>
        <Switch>
          {routers.map((route, i) => {
            if (route.type !== '4') {
              const routers = []

              routers.push(<Route key={i} {...route} exact />)

              if (!defaultPages.find(x => x.type === route.type)) {
                defaultPages.push({
                  type: route.type,
                  path: route.slug,
                })
              }

              if (route.type === '1') {
                routers.push(<Route exact path={route.slug + '/szukaj/:query'} component={ArchiveSearch} />)
                routers.push(<Route exact path={route.slug + '/:year/:month/:page'} component={ArchiveView} />)
                routers.push(<Route exact path={route.slug + '/:year/:month/:page/:type'} component={ArchiveView} />)
                routers.push(<Route exact path={route.slug + '/:year/:month'} component={ArchiveMonth} />)
                routers.push(<Route exact path={route.slug + '/:year'} component={ArchiveYear} />)
              } else if (route.type === '2') {
                routers.push(<Route exact path={route.slug + '/rejestracja'} component={UserRegister} />)
                routers.push(<Route exact path={route.slug + '/activate/:id'} component={UserActivate} />)
                routers.push(<Route exact path={route.slug + '/przypomnij'} component={UserRemind} />)
                routers.push(<Route exact path={route.slug + '/logowanie'} component={UserLogin} />)
                routers.push(<Route exact path={route.slug + '/logout'} component={UserLogout} />)
                routers.push(<Route exact path={route.slug + '/reset/:id'} component={UserReset} />)
              }

              return routers
            } else {
              return false
            }
          })}
          <Route exact path="/" component={Home} />
          <Route path="*" component={NotFound} status={404} />
        </Switch>
      </Suspense>
    )
  } else {
    // content = (
    //   <Suspense fallback={<div />}>
    //     <ErrorPage />
    //   </Suspense>
    // )

    return false
  }

  let layout = <div className="content">{content}</div>
  if (data.type === 'slots') {
    layout = (
      <Fragment>
        <div className="row">
          <Slot id="9" />
        </div>
        <div className="main row">
          <aside>
            <Slot id="7" />
          </aside>
          <div className="content" id="main-content">
            {content}
          </div>
          <aside>
            <Slot id="8" />
          </aside>
        </div>
        <div className="row">
          <Slot id="10" />
        </div>
      </Fragment>
    )
  }

  const showMenuHandler = state => {
    setShowMenu(typeof state !== 'object' ? state : !showMenu)
  }

  const contrastHandler = e => {
    e.preventDefault()

    if (contrast.state === 0) {
      setContrast({
        state: 1,
        title: 'Wersja domyślna',
      })
    } else {
      setContrast(defaultContrast)
    }
  }

  const fontSizeHandler = e => {
    e.preventDefault()

    var root = document.getElementsByTagName('html')[0]
    root.classList.remove('font-size-1')
    root.classList.remove('font-size-2')

    if (fontSize.state === 0) {
      root.classList.add('font-size-1')

      setFontSize({
        state: 1,
        title: 'Czcionka duża',
      })
    } else if (fontSize.state === 1) {
      root.classList.add('font-size-2')

      setFontSize({
        state: 2,
        title: 'Czcionka domyślna',
      })
    } else {
      setFontSize(defaultFontSize)
    }
  }

  let meta = {}
  if (data.length !== 0) {
    meta = {
      title: data.title,
      description: data.description,
      meta: {
        name: {
          keywords: data.keywords,
        },
      },
    }

    if (data.track) {
      GA.initialize(data.track)
      GA.pageview(window.location.pathname + window.location.search)
    }
  }

  return (
    <DocumentMeta {...meta}>
      <div
        className={
          'app ' +
          (window.location.pathname === '/' ? 'home' : '') +
          (showMenu ? ' menu-show' : '') +
          (contrast.state === 1 ? ' contrast-black-yellow' : '')
        }
      >
        <Router>
          <Fragment>
            <SiteContext.Provider
              value={{
                data: data,
                setData: setData,
                showMenu: showMenu,
                setShowMenu: showMenuHandler,
                user: user,
                setUser: setUser,
                messages: messages,
                setMessages: setMessages,
                defaultPages: defaultPages,
              }}
            >
              <WaiNavigation />
              <SecondNavigation contrastHandler={contrastHandler} fontSizeHandler={fontSizeHandler} contrast={contrast} fontSize={fontSize} />
              <Header routers={routers} />
              <main>
                <Messages />
                {layout}
              </main>
              <div className="slot4-container">
                <div className="row">
                  <Slot id="4" />
                </div>
              </div>
              <footer className="main-footer">
                <div className="row">
                  <Slot id="5" />
                  <Slot id="6" />
                  <label className="hide" htmlFor="g-recaptcha-response-100000">
                    Google recaptcha
                  </label>
                </div>
              </footer>
              <Consent />
            </SiteContext.Provider>
          </Fragment>
        </Router>
      </div>
    </DocumentMeta>
  )
}

export default app
